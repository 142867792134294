import {
    OrderValue,
    OrderUpdateValue,
    OrderItemValue,
    OrderDetailsValue,
    OrderAddressGeoPositionValue,
    OrderDetailsAddressValue,
    OrderPaymentValue,
    ItemUpdateBookingValue,
    OrderTransactionsUpdateValue,
    OrderPassengerUpdateValue,
    OrderPassengerCreateValue,
    OrderPersonalDetailsValue,
    OrderParcelUpdateValue,
    OrderParcelCreateValue,
    OrderInvoiceDetailsValue,
} from './order.value';
import { GeoPositionModel } from '@app/store/planning/models';
import { PLANNING_TYPE } from '@app/store/planning/enums';
import { CUSTOMER_TYPE } from '../enums/customer-type.enum';
import { PAYMENT_TYPES, CURRENCY_TYPES, ITEM_STATUS, PAYMENT_PROVIDERS } from '../enums';
import { SERVICE_TYPES } from '@app/store/payment/enums';
import { ACCURACY_TYPES } from '../enums/accuracy.enum';

/**
 * MAIN
 */
export class OrderValueDto {
    partnerId: string | null;
    teamNumber: string | null;
    customer: OrderCustomerValueDto;
    passengers: OrderPassengerCreateDto[];
    parcels: OrderParcelCreateDto[];
    transactions: OrderPaymentValueDto[];
    invoice: boolean;
    constructor(data: OrderValue) {
        this.partnerId = data.details.partner;
        this.teamNumber = data.details.teamNumber ? data.details.teamNumber.trim() : null;
        this.customer = new OrderCustomerValueDto(data.details, data.invoiceDetails);
        this.passengers = data.passengers?.map((item) => new OrderPassengerCreateDto(item)) || [];
        this.parcels = data.parcels?.map((item) => new OrderParcelCreateDto(item)) || [];
        this.transactions = data.transactions.map((item) => new OrderPaymentValueDto(item));
        this.invoice = data.invoiceDetails.invoice;
    }
}

export class OrderUpdateValueDto {
    partnerId: string | null;
    teamNumber: string | null;
    customer: OrderCustomerValueDto;
    passengers: OrderPassengerUpdateDto[];
    parcels: OrderParcelUpdateDto[];
    transactions: OrderTransactionsUpdateDto[];
    invoice: boolean;
    constructor(order: OrderUpdateValue) {
        this.partnerId = order.details.partner;
        this.teamNumber = order.details.teamNumber;
        this.customer = new OrderCustomerValueDto(order.details, order.invoiceDetails);
        this.passengers = order.passengers?.map((item) => new OrderPassengerUpdateDto(item)) || [];
        this.parcels = order.parcels?.map((item) => new OrderParcelUpdateDto(item)) || [];
        this.transactions = order.transactions.map((item) => new OrderTransactionsUpdateDto(item));
        this.invoice = order.invoiceDetails.invoice;
    }
}

/**
 * PARTS
 */
export class OrderCustomerValueDto {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phone: string | null;
    companyName: string | null;
    nip: string | null;
    type: CUSTOMER_TYPE | null;
    address: OrderDetailsAddressValueDto | null;
    constructor(data: OrderDetailsValue, invoice: OrderInvoiceDetailsValue) {
        this.firstName = data.firstName?.trim() ?? null;
        this.lastName = data.lastName?.trim() ?? null;
        this.email = data.email?.trim() ?? null;
        this.phone = data.phone?.trim() ?? null;
        this.companyName = invoice.isCompany ? invoice.companyName : null;
        this.nip = invoice.nip && invoice.invoice === true ? invoice.nip : null;
        this.type = invoice.isCompany === true ? CUSTOMER_TYPE.COMPANY : CUSTOMER_TYPE.PERSON;
        this.address = invoice.invoice ? new OrderDetailsAddressValueDto(invoice.address) : null;
    }
}

export class OrderInvoiceDetailsValueDto {
    companyName: string | null;
    nip: string | null;
    type: CUSTOMER_TYPE | null;
    address: OrderDetailsAddressValueDto | null;
    constructor(data: OrderInvoiceDetailsValue) {
        this.companyName = data.isCompany ? data.companyName : null;
        this.nip = data.nip && data.invoice ? data.nip : null;
        this.type = data.isCompany === true ? CUSTOMER_TYPE.COMPANY : CUSTOMER_TYPE.PERSON;
        this.address = data.invoice ? new OrderDetailsAddressValueDto(data.address) : null;
    }
}
export class OrderPaymentValueDto {
    currency: CURRENCY_TYPES | null;
    paymentMethod: PAYMENT_TYPES | null | 'payment_processor';
    paymentProvider: PAYMENT_PROVIDERS | null;
    constructor(data: OrderPaymentValue) {
        this.currency = data.currency;
        this.paymentMethod =
            data.paymentMethod === PAYMENT_TYPES.STRIPE || data.paymentMethod === PAYMENT_TYPES.PAYU ? 'payment_processor' : data.paymentMethod;
        this.paymentProvider =
            data.paymentMethod === PAYMENT_TYPES.STRIPE ? PAYMENT_PROVIDERS.STRIPE : data.paymentMethod === PAYMENT_TYPES.PAYU ? PAYMENT_PROVIDERS.PAYU : null;
    }
}
export class OrderDetailsAddressValueDto {
    country: string | null;
    city: string | null;
    street: string | null;
    houseNumber: string | null;
    postalCode: string | null;

    constructor(data: OrderDetailsAddressValue) {
        this.country = data.country ? data.country : null;
        this.city = data.city;
        this.street = data.street;
        this.houseNumber = data.houseNumber;
        this.postalCode = data.postalCode;
    }
}

export class GeoPositionValueDto {
    longitude: number | null;
    latitude: number | null;
    constructor(data: GeoPositionModel) {
        this.longitude = data.longitude;
        this.latitude = data.latitude;
    }
}

export class OrderPassengerUpdateDto {
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
    email: string | null;
    driverNotes?: string | null;
    internalNotes?: string | null;
    notes: string | null;
    route: OrderRouteValueDto;
    bookingId: string | null;
    didntGo: boolean;
    action: string;
    complaint: boolean;
    didntGoReason: number | null;
    resignReason: number | null;
    constructor(data: OrderPassengerUpdateValue) {
        this.firstName = data.personalDetails.firstname;
        this.lastName = data.personalDetails.surname;
        this.phone = data.personalDetails.phone;
        this.email = data.personalDetails.email === '' ? null : data.personalDetails.email;
        this.driverNotes = data.driverNotes;
        this.internalNotes = data.internalNotes;
        this.notes = data.notes;
        this.route = new OrderRouteValueDto(data);
        this.bookingId = data.id;
        this.didntGo = data.didntGo;
        this.action = this.bookingId ? 'update' : 'create';
        this.complaint = data.complaint ? data.complaint : false;
        this.didntGoReason = data.didntGoReason ? data.didntGoReason : null;
        this.resignReason = data.resignReason ? data.resignReason : null;
    }
}

export class OrderPassengerCreateDto {
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
    email: string | null;
    driverNotes?: string | null;
    internalNotes?: string | null;
    notes: string | null;
    route: OrderRouteValueDto;
    didntGo: boolean;
    action: string;
    items: OrderItemValueDTO[];
    constructor(data: OrderPassengerCreateValue) {
        this.firstName = data.personalDetails.firstname?.trim() ?? null;
        this.lastName = data.personalDetails.surname?.trim() ?? null;
        this.phone = data.personalDetails.phone?.trim() ?? null;
        this.email = data.personalDetails.email === '' ? null : data.personalDetails.email?.trim() ?? null;
        this.driverNotes = data.driverNotes?.trim() ?? null;
        this.internalNotes = data.internalNotes?.trim() ?? null;
        this.route = new OrderRouteValueDto(data);
        this.didntGo = data.didntGo;
        this.notes = data.notes?.trim() ?? null;
        this.action = 'create';
        this.items = data.items.map((item) => new OrderItemValueDTO(item));
    }
}

export class OrderParcelCreateDto {
    recipient: OrderPersonalDetailsDto;
    packageAmount: number;
    size: string;
    weight: number | null;
    value: number;
    contentDescription: string | null;
    notes: string | null;
    driverNotes: string | null;
    route: OrderRouteValueDto;
    didntGo: boolean;
    action: string;
    bookingId?: string;
    items: OrderItemValueDTO[];
    internalNotes: string | null;
    constructor(data: OrderParcelCreateValue) {
        this.recipient = new OrderPersonalDetailsDto(data.recipient);
        this.packageAmount = 1;
        this.size = data.size;
        this.weight = data.weight;
        this.value = data.value;
        this.contentDescription = data.contentDescription;
        this.driverNotes = data.driverNotes;
        this.notes = data.notes;
        this.internalNotes = data.internalNotes;
        this.route = new OrderRouteValueDto(data);
        this.didntGo = data.didntGo;
        this.action = 'create';
        this.items = data.items.map((item) => new OrderItemValueDTO(item));
    }
}
export class OrderRouteValueDto {
    // panelId?: string | null; #FIXEM
    // price?: number; #FIXEM
    departureDate: string | null;
    planningType: PLANNING_TYPE | null;
    from: OrderAddressGeoPositionValueDto | null;
    to: OrderAddressGeoPositionValueDto | null;
    constructor(data: OrderPassengerUpdateValue | OrderParcelUpdateValue) {
        this.from = data.from ? new OrderAddressGeoPositionValueDto(data.from) : null;
        this.to = data.to ? new OrderAddressGeoPositionValueDto(data.to) : null;
        this.departureDate = data.departureDate
            ? `${data.departureDate.getFullYear()}-${(data.departureDate.getMonth() + 1).toString().padStart(2, '0')}-${data.departureDate.getDate().toString().padStart(2, '0')}`
            : null;
        this.planningType = data.planningType;
    }
}

export class OrderAddressGeoPositionValueDto {
    panelId: number | null;
    searchValue: string;
    isPoint: boolean;
    hourFrom: string | null;
    hourTo: string | null;
    country: string | null;
    city: string | null;
    voivodeship: string | null;
    district: string | null;
    street: string | null;
    houseNumber: string | null;
    flatNumber: string | null;
    postalCode: string | null;
    locationId: string | null;
    geoPosition: GeoPositionValueDto;
    accuracy: ACCURACY_TYPES;
    constructor(data: OrderAddressGeoPositionValue) {
        this.panelId = null;
        this.searchValue = data.term;
        this.isPoint = data.isPoint;
        this.hourFrom = null;
        this.hourTo = null;
        this.country = data.country || null;
        this.city = data.city;
        this.voivodeship = null;
        this.district = null;
        this.street = data.street;
        this.houseNumber = data.houseNumber;
        this.flatNumber = null;
        this.postalCode = data.postalCode;
        this.locationId = null;
        this.geoPosition = new GeoPositionValueDto(data.geoPosition);
        this.accuracy = data.accuracy;
    }
}

export class OrderItemValueDTO {
    id?: string;
    notes: string | null;
    price: number;
    type: number | null;
    constructor(data: OrderItemValue) {
        if (data.id !== null) {
            this.id = data.id;
        }
        this.notes = data.notes;
        this.price = Math.round((data.price || 0) * 100);
        this.type = data.type;
    }
}

export class OrderTransactionsUpdateDto {
    currency: CURRENCY_TYPES | null;
    paymentMethod: PAYMENT_TYPES | null | 'payment_processor';
    paymentProvider: PAYMENT_PROVIDERS | null;
    items: ItemUpdateBookingDto[];
    id: string | null;
    action?: string;
    closed: boolean;
    paid: boolean;
    constructor(data: OrderTransactionsUpdateValue) {
        this.currency = data.currency ? data.currency : CURRENCY_TYPES.PLN;
        this.paymentMethod =
            data.paymentMethod === PAYMENT_TYPES.STRIPE || data.paymentMethod === PAYMENT_TYPES.PAYU ? 'payment_processor' : data.paymentMethod;
        this.paymentProvider =
            data.paymentMethod === PAYMENT_TYPES.STRIPE ? PAYMENT_PROVIDERS.STRIPE : data.paymentMethod === PAYMENT_TYPES.PAYU ? PAYMENT_PROVIDERS.PAYU : null;
        this.items = data.items.map((item) => new ItemUpdateBookingDto(item));
        if (data.id) {
            this.id = data.id;
            this.action = 'update';
        } else {
            this.id = null;
            this.action = 'create';
        }
        this.closed = data.closed;
        this.paid = data.paid;
    }
}
export class ItemUpdateBookingDto {
    name: string | null;
    notes: string | null;
    price: number;
    type: SERVICE_TYPES | null;
    id: string | null;
    bookingId: string | null;
    resigned: boolean;
    action?: string;
    constructor(data: ItemUpdateBookingValue) {
        this.name = data.name;
        this.notes = data.notes;
        this.price = Math.round(data.price * 100);
        this.type = data.type;
        this.resigned = data.status === ITEM_STATUS.RESIGNED;
        this.bookingId = data.bookingId;
        if (data.id) {
            this.id = data.id;
            this.action = 'update';
        } else {
            this.id = null;
            this.action = 'create';
        }
    }
}

export class OrderParcelUpdateDto {
    recipient: OrderPersonalDetailsDto;
    packageAmount: number;
    size: string;
    weight: number | null;
    value: number;
    notes: string | null;
    internalNotes: string | null;
    contentDescription: string | null;
    driverNotes: string | null;
    route: OrderRouteValueDto;
    didntGo: boolean;
    complaint: boolean;
    bookingId: string | null;
    action: string;
    didntGoReason: number | null;
    resignReason: number | null;
    constructor(data: OrderParcelUpdateValue) {
        this.recipient = new OrderPersonalDetailsDto(data.recipient);
        this.packageAmount = 1;
        this.size = data.size?.trim() ?? null;
        this.weight = data.weight;
        this.value = data.value;
        this.notes = data.notes?.trim() ?? null;
        this.internalNotes = data.internalNotes?.trim() ?? null;
        this.driverNotes = data.driverNotes?.trim() ?? null;
        this.route = new OrderRouteValueDto(data);
        this.contentDescription = data.contentDescription?.trim() ?? null;
        this.didntGo = data.didntGo;
        this.bookingId = data.id;
        this.action = 'update';
        this.complaint = data.complaint ? data.complaint : false;
        this.didntGoReason = data.didntGoReason ? data.didntGoReason : null;
        this.resignReason = data.resignReason ? data.resignReason : null;
    }
}

export class OrderPersonalDetailsDto {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phone: string | null;

    constructor(data: OrderPersonalDetailsValue) {
        this.firstName = data.firstname?.trim() ?? null;
        this.lastName = data.surname?.trim() ?? null;
        this.email = data.email?.trim() ?? null;
        this.phone = data.phone?.trim() ?? null;
    }
}
